<template>
  <div class="_bg-default d-flex justify-center align-center">
    <v-card class="pa-3 mb-3" max-width="400" v-if="!isSent">
      <h3 class="indigo--text">Kirimkan masukan kepada kami!</h3>
      <p class="grey--text">
        Apakah Anda punya saran atau menemukan galat? beri tahu kami pada kolom
        komentar di bawah.
      </p>
      <vue-editor
        placeholder="Isi feedback.."
        v-model="feed"
        :editorToolbar="customToolbar"
        class="white"
      ></vue-editor>
      <v-radio-group v-model="tipe" row>
        <v-radio label="Galat" value="Bug"></v-radio>
        <v-radio label="Komentar" value="Komentar"></v-radio>
        <v-radio label="Lainya.." value="Lainya"></v-radio>
      </v-radio-group>
      <v-btn color="indigo" dark @click="sendFeedback">Kirim</v-btn>
      <!-- <footer class="mt-3">
        <v-divider></v-divider>
        <small>
          *Bug : kesalahan yang terjadi pada perangkat komputer atau mobile yang
          disebabkan oleh perangkat lunak atau perangkat keras yang tidak
          berjalan sesuai dengan semestinya sehingga menyebabkan error.</small
        >
      </footer> -->
    </v-card>
    <v-card class="pa-10" max-width="400" v-if="isSent">
      <div class="d-flex flex-column align-center">
        <h1 class="indigo--text">Terima kasih! Pesan Anda telah terkirim.</h1>
        <v-icon size="100px" color="green accent-1"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
      </div>
    </v-card>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      isSent: false,
      loading: false,
      tipe: "",
      feed: "",
      file: null,
      editor: null,
      cursorLocation: null, 
      resetUploader: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }],
        ["image"]
      ]
    };
  },
  methods: {
    sendFeedback() {
      this.loading = true;
      this.$store
        .dispatch("feedback/feedbackForApp", {
          judul: this.tipe,
          isi_feedback: this.feed
        })
        .then(() => {
          this.tipe = "";
          this.feed = "";
          this.loading = false;
          this.isSent = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // handleImageAdded(file, Editor, cursorLocation, resetUploader) {
    //   this.file= file,
    //   this.editor= Editor,
    //   this.cursorLocation= cursorLocation, 
    //   this.resetUploader= resetUploader,
    //   console.log(this.file, this.editor, this.cursorLocation, this.resetUploader);
    // }
  }
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
